exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../../../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payed-mobile-tsx": () => import("./../../../src/pages/payed_mobile.tsx" /* webpackChunkName: "component---src-pages-payed-mobile-tsx" */),
  "component---src-pages-payed-tsx": () => import("./../../../src/pages/payed.tsx" /* webpackChunkName: "component---src-pages-payed-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-promo-tsx": () => import("./../../../src/pages/promo.tsx" /* webpackChunkName: "component---src-pages-promo-tsx" */),
  "component---src-templates-categories-template-tsx": () => import("./../../../src/templates/categoriesTemplate.tsx" /* webpackChunkName: "component---src-templates-categories-template-tsx" */)
}

